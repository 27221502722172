import axios from "../../api";

export function login(email, password, type) {
  //   alert("teste");
  console.log(email, type);
  return axios.post("/conta/login/" + type, {
    email,
    password,
  });
}

export function forgotPassword(email, type) {
  return axios.put("/conta/" + type + "/recover/password", {
    email,
  });
}

export function showBaseUser(id) {
  return axios;
}
